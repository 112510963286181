var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container",
      style: { backgroundImage: _vm.backgroundImage },
    },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.tabIndex === "login" ? _vm.loginRules : _vm.reRules,
            autocomplete: "on",
            "label-position": "left",
            size: "medium",
          },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v("账户" + _vm._s(_vm.tabIndex === "login" ? "登录" : "注册")),
          ]),
          !_vm.forgotPassword
            ? _c(
                "el-tabs",
                {
                  attrs: { stretch: true },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "登录", name: "login" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "account" } },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "user" },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            ref: "account",
                            attrs: {
                              placeholder: "账户名/手机号",
                              name: "account",
                              type: "text",
                              tabindex: "1",
                              autocomplete: "on",
                            },
                            model: {
                              value: _vm.loginForm.account,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "account", $$v)
                              },
                              expression: "loginForm.account",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "password" },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            key: _vm.passwordType,
                            ref: "password",
                            attrs: {
                              type: _vm.passwordType,
                              placeholder: "登录密码",
                              name: "password",
                              tabindex: "2",
                              autocomplete: "on",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleLogin($event)
                              },
                            },
                            model: {
                              value: _vm.loginForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "password", $$v)
                              },
                              expression: "loginForm.password",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "show-pwd",
                              on: { click: _vm.showPwd },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  "icon-class":
                                    _vm.passwordType === "password"
                                      ? "eye"
                                      : "eye-open",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "captcha" } },
                        [
                          _c(
                            "span",
                            { staticClass: "svg-container" },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": "lock" },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            ref: "captcha",
                            attrs: {
                              placeholder: "验证码",
                              name: "code",
                              tabindex: "3",
                              autocomplete: "off",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleLogin($event)
                              },
                            },
                            model: {
                              value: _vm.captcha.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.captcha, "code", $$v)
                              },
                              expression: "captcha.code",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "change-code",
                              on: { click: _vm.handleCaptcha },
                            },
                            [
                              _c("input", {
                                attrs: { type: "hidden", name: "uniqid" },
                                domProps: { value: _vm.captcha.uniqid },
                              }),
                              _c("img", { attrs: { src: _vm.captcha.image } }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "tips text-df" },
                        [
                          _c("el-link", { on: { click: _vm.handlePassword } }, [
                            _vm._v("验证登录"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "my-button",
                          staticStyle: { width: "100%", "margin-top": "30px" },
                          attrs: {
                            loading: _vm.btnLoading,
                            type: "primary",
                            size: "medium",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLogin($event)
                            },
                          },
                        },
                        [_vm._v("登录")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "注册", name: "register" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "name" } },
                        [
                          _c("span", { staticClass: "svg-container" }, [
                            _c("i", { staticClass: "el-icon-user" }),
                          ]),
                          _c("el-input", {
                            ref: "name",
                            attrs: {
                              placeholder: "姓名",
                              name: "name",
                              type: "text",
                              size: "medium",
                              tabindex: "1",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.loginForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "name", $$v)
                              },
                              expression: "loginForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "mobile" } },
                        [
                          _c("span", { staticClass: "svg-container" }, [
                            _c("i", { staticClass: "el-icon-mobile-phone" }),
                          ]),
                          _c("el-input", {
                            ref: "mobile",
                            attrs: {
                              placeholder: "手机号",
                              name: "mobile",
                              type: "text",
                              size: "medium",
                              tabindex: "1",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.loginForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "mobile", $$v)
                              },
                              expression: "loginForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "captcha" } },
                        [
                          _c("span", { staticClass: "svg-container" }, [
                            _c("i", { staticClass: "el-icon-message" }),
                          ]),
                          _c("el-input", {
                            ref: "captcha",
                            attrs: {
                              placeholder: "验证码",
                              name: "code",
                              size: "medium",
                              tabindex: "2",
                              autocomplete: "off",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleRegister($event)
                              },
                            },
                            model: {
                              value: _vm.captcha.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.captcha, "code", $$v)
                              },
                              expression: "captcha.code",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "change-code",
                              on: { click: _vm.handleCode },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: _vm.btnDisabled,
                                    type: "text",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.btnText))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "tips text-right text-df" }, [
                        _c("span", [_vm._v("已注册手机验证后自动登录")]),
                      ]),
                      _c(
                        "el-button",
                        {
                          staticClass: "my-button",
                          staticStyle: { width: "100%", "margin-top": "30px" },
                          attrs: {
                            loading: _vm.btnLoading,
                            type: "primary",
                            size: "medium",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleRegister($event)
                            },
                          },
                        },
                        [_vm._v("注册/登录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.forgotPassword
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "mobile" } },
                    [
                      _c("span", { staticClass: "svg-container" }, [
                        _c("i", { staticClass: "el-icon-mobile-phone" }),
                      ]),
                      _c("el-input", {
                        ref: "mobile",
                        attrs: {
                          placeholder: "手机号码",
                          name: "mobile",
                          type: "text",
                          size: "medium",
                          tabindex: "1",
                          autocomplete: "on",
                        },
                        model: {
                          value: _vm.loginForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "mobile", $$v)
                          },
                          expression: "loginForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "captcha" } },
                    [
                      _c("span", { staticClass: "svg-container" }, [
                        _c("i", { staticClass: "el-icon-message" }),
                      ]),
                      _c("el-input", {
                        ref: "captcha",
                        attrs: {
                          placeholder: "验证码",
                          name: "code",
                          size: "medium",
                          tabindex: "2",
                          autocomplete: "off",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleMobile($event)
                          },
                        },
                        model: {
                          value: _vm.captcha.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.captcha, "code", $$v)
                          },
                          expression: "captcha.code",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "change-code",
                          on: { click: _vm.handleCode },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.btnDisabled,
                                type: "text",
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnText))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tips text-df" },
                    [
                      _c("el-link", { on: { click: _vm.handlePassword } }, [
                        _vm._v("账户登录"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "my-button",
                      staticStyle: { width: "100%", "margin-top": "30px" },
                      attrs: {
                        loading: _vm.btnLoading,
                        type: "primary",
                        size: "medium",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleMobile($event)
                        },
                      },
                    },
                    [_vm._v("验证登录")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }