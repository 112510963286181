"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {
          background_image: ''
        };
      }
    }
  }
};
exports.default = _default2;