"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
var _folder = require("@/api/folder");
var state = {};
var mutations = {};
var actions = {
  // 获取文件夹列表
  getFolder: function getFolder(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _folder.getList)().then(function (response) {
        var data = response.data;
        data.forEach(function (item) {
          item.value = item.id;
          item.label = item.title;
          // 文件库分组列表需要用到
          item.name = item.id.toString();
        });
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;