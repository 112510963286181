var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-container" },
    [
      _c(
        "el-dropdown",
        { style: "color:" + _vm.textColor + ";", attrs: { trigger: "click" } },
        [
          _c("div", { staticClass: "avatar-wrapper" }, [
            _vm.user.avatar
              ? _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.user.avatar + "?imageView2/1/w/80/h/80" },
                })
              : _vm._e(),
            _c("span", { staticClass: "text-df padding-left-sm" }, [
              _vm._v(_vm._s(_vm.user.name)),
            ]),
            _c("i", { staticClass: "el-icon-caret-bottom" }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c("el-dropdown-item", [
                _c(
                  "div",
                  {
                    staticClass: "profile-item",
                    on: { click: _vm.handleCurrent },
                  },
                  [
                    _c("span", [_vm._v("账户设置")]),
                    _c("i", { staticClass: "el-icon-arrow-right" }),
                  ]
                ),
              ]),
              _c("el-dropdown-item", [
                _c(
                  "div",
                  {
                    staticClass: "profile-item",
                    on: { click: _vm.handleChangeFirm },
                  },
                  [
                    _c("div", { staticClass: "text-cut" }, [
                      _vm._v(" " + _vm._s(_vm.user.firm_title) + " "),
                    ]),
                    _c("div", { staticClass: "flex-shrink" }, [
                      _c("span", [_vm._v("切换")]),
                      _c("i", { staticClass: "el-icon-arrow-right" }),
                    ]),
                  ]
                ),
              ]),
              _c(
                "el-dropdown-item",
                {
                  staticStyle: { display: "block", "text-align": "center" },
                  attrs: { divided: "" },
                },
                [
                  _c("span", { on: { click: _vm.handleLogout } }, [
                    _vm._v("退出登录"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }