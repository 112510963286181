"use strict";

var _interopRequireDefault = require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _defineProperty2 = _interopRequireDefault(require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _image = _interopRequireDefault(require("@/components/Upload/image"));
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
var _microApp = _interopRequireWildcard(require("@micro-zoe/micro-app"));
var _vuex = require("vuex");
var _components = require("./components");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  name: 'Layout',
  components: {
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Setting: _components.Setting,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    Breadcrumb: _Breadcrumb.default
  },
  mixins: [_ResizeHandler.default],
  computed: _objectSpread(_objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['apps', 'user', 'token', 'authorize'])), (0, _vuex.mapState)({
    layout: function layout(state) {
      return state.core.config.layout;
    },
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSetting: function showSetting(state) {
      return state.setting.showSetting;
    },
    needTagsView: function needTagsView(state) {
      return state.setting.tagsView;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        top: this.layout === 'top',
        left: this.layout === 'left',
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        hasTagsView: this.needTagsView,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    path: function path() {
      return this.$route.path;
    },
    app: function app() {
      var name = this.$route.meta.app ? this.$route.meta.app : '';
      var app = this.apps.find(function (item) {
        return item.name == name;
      });
      if (typeof app === 'undefined') {
        return {
          name: 'base',
          entry: ''
        };
      }
      if (typeof app.entry === 'string' && app.entry.indexOf('/') == 0) {
        app.entry = window.location.origin + app.entry;
      }
      // 关闭沙箱模式后绑定通讯对象
      if (!app.sandbox) {
        window[app.name + 'EventCenter'] = new _microApp.EventCenterForMicroApp(app.name);
      }
      return app;
    }
  }),
  watch: {
    $route: function $route() {
      this.setAppData();
    }
  },
  methods: {
    closeSideBar: function closeSideBar() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    created: function created(e) {
      // console.log('元素被创建', e)
    },
    beforemount: function beforemount(e) {
      this.setAppData();
      // console.log(e.detail.name, '即将被渲染',e)
    },
    mounted: function mounted(e) {
      // console.log(e.detail.name, '已经渲染完成',e)
    },
    unmount: function unmount(e) {
      // console.log(e.detail.name, '已经卸载',e)
    },
    error: function error(e) {
      // console.log(e.detail.name, '渲染出错', e)
    },
    // 下发子应用数据
    setAppData: function setAppData() {
      if (!this.app.name) {
        return;
      }
      _microApp.default.setData(this.app.name, {
        app: this.app,
        user: this.user,
        token: this.token,
        route: this.$route,
        authorize: this.authorize,
        components: {
          Upload: _image.default
        }
      });
    },
    // 监听子应用数据
    onDataChange: function onDataChange(e) {
      var data = e.detail.data;
      // 控制基座路由

      if (data.route) {
        this.$router.push({
          path: data.route.path || '/',
          hash: data.route.hash || '',
          query: data.route.query || {},
          params: data.route.params || ''
        });
      }
      // 监听应用消息
      if (data.msg) {
        this.$store.dispatch('app/sendMsg', data.msg);
      }
    }
  }
};
exports.default = _default;