"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _default = {
  methods: {
    validateUpload: function validateUpload(file) {
      for (var _len = arguments.length, props = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        props[_key - 1] = arguments[_key];
      }
      var accept = props[0],
        size = props[1];
      // 判断文件类型
      var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
      var isImg = file.type.split('\/')[0] === 'image';
      var isVideo = file.type.split('\/')[0] === 'image';
      var isAccept = accept.split(',').indexOf('.' + ext) >= 0;
      var isLt5M = file.size / 1024 / 1024 < (size || 5);
      var isLt10M = file.size / 1024 / 1024 < (size || 10);
      if (!isImg && !isVideo && !isAccept) {
        this.$message.error("\u6587\u4EF6\u683C\u5F0F\u4E0D\u5141\u8BB8!");
        return false;
      }
      if (isImg && !isLt5M) {
        this.$message.error("\u4E0A\u4F20\u56FE\u7247\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 ".concat(size, "MB!"));
        return false;
      }
      if (isVideo && !isLt10M) {
        this.$message.error("\u4E0A\u4F20\u89C6\u9891\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 ".concat(size, "MB!"));
        return false;
      }
      return true;
    },
    /**
     * data 文件上传的数据
     * uploadData 需要上传的参数对象
     * */
    createFormData: function createFormData(data, upLoadData) {
      // 上传
      var file = data.file;
      for (var _len2 = arguments.length, props = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        props[_key2 - 2] = arguments[_key2];
      }
      if (this.validateUpload.apply(this, [file].concat(props))) {
        var formData = new FormData();
        formData.append('file', file);
        for (var key in upLoadData) {
          // 其他参数
          formData.append(key, upLoadData[key]);
        }
        return formData;
      }
      return false;
    }
  }
};
exports.default = _default;