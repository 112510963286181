var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "图片库",
        visible: _vm.visible,
        center: "",
        width: "850px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-menu",
                {
                  staticClass: "menu-demo",
                  attrs: { "default-active": _vm.tabsValue },
                  on: { select: _vm.handleChange },
                },
                _vm._l(_vm.editableTabs, function (item) {
                  return _c(
                    "el-menu-item",
                    { key: item.id, attrs: { index: item.name } },
                    [
                      Number(item.id) > 0 && _vm.tabsValue == item.id
                        ? _c("i", {
                            staticClass: "el-icon-edit fl margin-top",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleEdit(item)
                              },
                            },
                          })
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          staticClass: "one-hidden",
                          attrs: { slot: "title", title: item.title },
                          slot: "title",
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      Number(item.id) > 0 && _vm.tabsValue == item.id
                        ? _c("i", {
                            staticClass: "el-icon-close fr margin-top",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleCancelGroup(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "margin-top-sm",
                  attrs: { type: "flex", justify: "center" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "success",
                        icon: "el-icon-plus",
                      },
                      on: { click: _vm.handleAdd },
                    },
                    [_vm._v("添加分组")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "el-row",
                { staticClass: "margin-bottom-sm margin-left-xs" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: { command: _vm.handleTransfer },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "mini", type: "primary" } },
                            [
                              _vm._v("移动至分组"),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            _vm._l(
                              _vm.editableTabs.filter(function (item) {
                                return item.id > 0
                              }),
                              function (item) {
                                return _c(
                                  "el-dropdown-item",
                                  {
                                    key: item.id,
                                    attrs: {
                                      command: item.name,
                                      disabled: item.name === _vm.tabsValue,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "margin-left-sm",
                          attrs: {
                            size: "mini",
                            type: "danger",
                            icon: "el-icon-delete",
                          },
                          on: { click: _vm.handleCancel },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "imgUpload",
                          attrs: {
                            action: "#",
                            accept: _vm.accept,
                            multiple: _vm.multiple,
                            "show-file-list": false,
                            "http-request": _vm.handleUpload,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "success",
                                icon: "el-icon-upload",
                              },
                            },
                            [_vm._v("上传图片")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.imgLoading,
                      expression: "imgLoading",
                    },
                  ],
                  staticClass: "image-content",
                  attrs: { gutter: 10 },
                },
                _vm._l(_vm.imageData.data, function (item, index) {
                  return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "image-item padding-tb margin-lr-sm margin-bottom-sm flex flex-direction justify-center align-center",
                        on: {
                          click: function ($event) {
                            return _vm.handleCheck(index, item)
                          },
                        },
                      },
                      [
                        item.type === "image"
                          ? _c("img", {
                              attrs: {
                                src: item.url,
                                alt: item.name,
                                title: item.name,
                              },
                            })
                          : item.type === "video"
                          ? _c(
                              "video",
                              {
                                attrs: {
                                  height: "90",
                                  src: item.url,
                                  alt: item.name,
                                  title: item.name,
                                  controls: "",
                                },
                              },
                              [_vm._v("您的浏览器不支持 video 标签。")]
                            )
                          : _c("div", { staticClass: "document" }, [
                              _c("i", { staticClass: "el-icon-document" }),
                            ]),
                        item.status
                          ? _c("div", { staticClass: "image-cover" }, [
                              _c("i", {
                                staticClass: "el-icon-check text-green",
                              }),
                            ])
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            staticClass: "margin-top one-hidden",
                            attrs: { title: item.name },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    ),
                  ])
                }),
                1
              ),
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.imageData.page,
                      "page-size": _vm.imageFilter.per_page,
                      total: _vm.imageData.total,
                      background: "",
                      layout: "total, prev, pager, next, jumper",
                    },
                    on: { "current-change": _vm.handleCurrentChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "margin-top",
          attrs: { type: "flex", justify: "center" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.updateRow },
            },
            [_vm._v("确认")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "danger" },
              on: { click: _vm.handleClose },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c("group-dialog", {
        ref: "groupDialog",
        on: { updateTabs: _vm.updateTabs },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }