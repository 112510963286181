var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex align-center justify-center",
    },
    [
      _c(
        "div",
        { staticClass: "body" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("进入企业")]),
          _c("div", { staticClass: "tip" }, [
            _vm._v("我们将在企业中为您提供 多门店管理 服务"),
          ]),
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "margin-top" },
            [
              _vm._l(_vm.owner, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "owner-item owner-create",
                    on: {
                      click: function ($event) {
                        return _vm.handleLogin(item.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "flex align-center" }, [
                      _c("div", { staticClass: "upload" }, [
                        _c("img", {
                          attrs: { src: item.image && item.image.url, alt: "" },
                        }),
                      ]),
                      _c("div", { staticClass: "owner-name" }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "create-tip flex justify-between" },
                          [
                            item.grade_title
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticClass: "margin-right-xs" },
                                    [_vm._v(_vm._s(item.grade_title))]
                                  ),
                                  item.expire_time
                                    ? _c("span", [
                                        _vm._v(
                                          " 到期时间：" +
                                            _vm._s(item.expire_time) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex align-center" }, [
                      item.owner_id !== _vm.user.id
                        ? _c(
                            "div",
                            {
                              staticClass: "quit text-right",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleQuit(item.id)
                                },
                              },
                            },
                            [_vm._v("退出企业")]
                          )
                        : _vm._e(),
                      _c("i", { staticClass: "el-icon-arrow-right" }),
                    ]),
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "owner-item owner-create",
                  on: {
                    click: function ($event) {
                      return _vm.handleOpenJoin("create")
                    },
                  },
                },
                [_vm._m(0), _vm._m(1)]
              ),
              _c(
                "div",
                {
                  staticClass: "join",
                  on: {
                    click: function ($event) {
                      return _vm.handleOpenJoin("join")
                    },
                  },
                },
                [_vm._v("已有企业，点击加入")]
              ),
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { width: "600px", visible: _vm.dialogVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "dialog-title" }, [
                  _vm._v(
                    _vm._s(_vm.type === "create" ? "创建" : "加入") + "企业"
                  ),
                ]),
              ]),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.type === "create" ? _vm.rules : _vm.joinRules,
                  },
                },
                [
                  _vm.type === "create"
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "title" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "输入企业名称" },
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "create"
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "region_id" } },
                        [
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "请选择所在地区",
                              options: _vm.region,
                              props: { label: "title", value: "id" },
                            },
                            model: {
                              value: _vm.form.region_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "region_id", $$v)
                              },
                              expression: "form.region_id",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type === "join"
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "输入邀请码" },
                            model: {
                              value: _vm.form.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _vm.type === "create"
                        ? _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "primary" },
                              on: { click: _vm.handleCreate },
                            },
                            [_vm._v("创 建")]
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "primary" },
                              on: { click: _vm.handleJoin },
                            },
                            [_vm._v("加 入")]
                          ),
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _vm.type === "create"
                      ? _c(
                          "div",
                          { staticClass: "flex-sub text-center" },
                          [
                            _c("span", [_vm._v("已有企业？")]),
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenJoin("join")
                                  },
                                },
                              },
                              [_vm._v("加入")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "flex-sub text-center" },
                          [
                            _c("span", [_vm._v("没有企业？")]),
                            _c(
                              "el-link",
                              {
                                attrs: { underline: false, type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenJoin("create")
                                  },
                                },
                              },
                              [_vm._v("创建")]
                            ),
                          ],
                          1
                        ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("div", { staticClass: "upload" }, [
        _c("i", { staticClass: "el-icon-plus" }),
      ]),
      _c("div", { staticClass: "owner-name" }, [
        _c("div", { staticClass: "name" }, [_vm._v("创建新的企业")]),
        _c("div", { staticClass: "create-tip" }, [
          _vm._v("可用于企业、组织或团队"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex align-center" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }