var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "background" }, [
    _c("div", {
      staticClass: "background-image",
      style: { backgroundImage: "url(" + _vm.config.background_image + ")" },
    }),
    _c(
      "div",
      {
        staticClass: "background-transform",
        style: { color: _vm.config.text_color },
      },
      [
        _c("div", { staticClass: "margin-bottom-xl text-xxl" }, [
          _c(
            "div",
            { staticClass: "padding-tb-sm text-center text-bold text-xxxl" },
            [_vm._v(_vm._s(_vm.config.title))]
          ),
          _c("div", { staticClass: "padding-tb-sm text-center" }, [
            _vm._v(_vm._s(_vm.config.slogan)),
          ]),
        ]),
      ]
    ),
    _c("div", { staticClass: "copyright" }, [
      _c("p", { style: { color: _vm.config.text_color } }, [
        _vm._v(_vm._s(_vm.config.copyright)),
      ]),
      _c(
        "a",
        {
          style: { color: _vm.config.text_color },
          attrs: {
            href: "https://beian.miit.gov.cn/#/Integrated/index",
            target: "https://beian.miit.gov.cn/#/Integrated/index",
          },
        },
        [_vm._v(_vm._s(_vm.config.icp))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }