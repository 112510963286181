var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "image-uploader",
          attrs: {
            "show-file-list": true,
            data: _vm.dataobj,
            action: _vm.action,
            headers: _vm.headers,
            multiple: _vm.multiple,
            name: _vm.fileName,
            accept: _vm.accept,
            drag: _vm.drag,
            limit: _vm.limit,
            "file-list": _vm.fileList,
            "on-preview": _vm.handlePreview,
            "on-exceed": _vm.handleExceed,
            "before-upload": _vm.beforeUpload,
            "on-progress": _vm.onProgress,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.handleError,
            "on-remove": _vm.handleRemove,
            "list-type": "picture-card",
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }