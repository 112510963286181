var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.collapse || (!_vm.collapse && _vm.logo)
    ? _c(
        "div",
        {
          staticClass: "sidebar-logo-container",
          class: { collapse: _vm.collapse },
        },
        [
          _c(
            "transition",
            { attrs: { name: "sidebarLogoFade" } },
            [
              _vm.collapse
                ? _c(
                    "router-link",
                    {
                      key: "collapse",
                      staticClass: "sidebar-logo-link",
                      attrs: { to: "/" },
                    },
                    [
                      _vm.logo
                        ? _c("img", {
                            staticClass: "sidebar-logo",
                            attrs: { src: _vm.logo },
                          })
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "router-link",
                    {
                      key: "expand",
                      staticClass: "sidebar-logo-link",
                      attrs: { to: "/" },
                    },
                    [
                      _vm.logo
                        ? _c("img", {
                            staticClass: "sidebar-logo",
                            attrs: { src: _vm.logo },
                          })
                        : _vm._e(),
                      _c("h1", { staticClass: "sidebar-title" }, [
                        _vm._v(_vm._s(_vm.title) + " "),
                      ]),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }