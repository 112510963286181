var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "flex align-center",
          class: { "justify-center": _vm.center },
        },
        [
          _c(
            "ul",
            { staticClass: "el-upload-list el-upload-list--picture-card" },
            [
              _vm._l(_vm.srcList, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "el-upload-list__item text-center",
                    style: {
                      width: _vm.width + "px",
                      height: _vm.height + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          width: _vm.width - 2 + "px",
                          height: _vm.height - 2 + "px",
                        },
                      },
                      [
                        item.type == "video"
                          ? _c("video", {
                              staticClass: "el-upload-list__item-thumbnail",
                              attrs: { src: item.url || item, alt: "" },
                            })
                          : item.type == "image"
                          ? _c("img", {
                              staticClass: "el-upload-list__item-thumbnail",
                              attrs: { src: item.url || item, alt: "" },
                            })
                          : (item.url || item) && item.type == undefined
                          ? _c("img", {
                              staticClass: "el-upload-list__item-thumbnail",
                              attrs: { src: item.url || item, alt: "" },
                            })
                          : _c(
                              "span",
                              { staticClass: "el-upload-list__item-file" },
                              [_c("i", { staticClass: "el-icon-document" })]
                            ),
                        _c(
                          "span",
                          { staticClass: "el-upload-list__item-actions" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "el-upload-list__item-preview",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleShow(item)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-zoom-in" })]
                            ),
                            !_vm.disabled
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "el-upload-list__item-delete",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleDelete(index)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-error" })]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm.width > 50
                      ? _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: item.name,
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "a",
                              { staticClass: "el-upload-list__item-name" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm.srcList.length < _vm.limit
                ? _c(
                    "li",
                    {
                      staticClass: "el-upload-list__item text-center",
                      style: {
                        width: _vm.width + "px",
                        height: _vm.height + "px",
                        border: "none",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        _vm._b(
                          {
                            staticClass: "image-uploader",
                            style: {
                              width: _vm.width + "px",
                              height: _vm.height + "px",
                            },
                            attrs: {
                              action: "#",
                              accept: _vm.accept,
                              "show-file-list": false,
                              "http-request": _vm.handleUpload,
                              "list-type": "picture-card",
                              disabled: _vm.disabled,
                            },
                          },
                          "el-upload",
                          _vm.$attrs,
                          false
                        ),
                        [
                          _c("i", {
                            staticClass: "el-icon-plus image-uploader-icon",
                          }),
                        ]
                      ),
                      _vm.library
                        ? _c(
                            "a",
                            {
                              staticClass: "text-blue text-sm",
                              staticStyle: { "text-decoration": "underline" },
                              on: { click: _vm.handleDialog },
                            },
                            [_vm._v("文件库")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "append-to-body": true,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("img", {
              attrs: {
                width: "450px",
                src: _vm.dialogImage.url,
                alt: _vm.dialogImage.name,
              },
            }),
          ]),
        ]
      ),
      _vm.library
        ? _c(
            "library",
            _vm._b(
              {
                ref: "fileDialog",
                attrs: { accept: _vm.accept, size: _vm.size, limit: _vm.limit },
                on: { updateRow: _vm.updateRow },
              },
              "library",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }