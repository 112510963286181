"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var _folder = require("@/api/folder");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'GroupDialog',
  data: function data() {
    return {
      temp: {},
      form: {
        title: ''
      },
      visible: false
    };
  },
  methods: {
    handleCreate: function handleCreate() {
      this.temp = {};
      this.form = {
        title: ''
      };
      this.visible = true;
    },
    handleEdit: function handleEdit(temp) {
      this.form = {
        title: temp.title
      };
      this.temp = temp;
      this.visible = true;
    },
    handleClose: function handleClose() {
      this.visible = false;
    },
    handleSubmit: function handleSubmit() {
      var _this = this;
      var success = function success(response) {
        if (response.code == 1) {
          if (!_this.temp.id) {
            _this.temp.id = response.data.id;
          }
          _this.temp.name = _this.temp.id.toString();
          _this.$emit('updateTabs', _this.temp);
          _this.$message.success(response.msg);
        } else {
          _this.$message.error(response.msg);
        }
      };
      var error = function error(_error) {
        _this.$message.error(_error.message);
      };
      Object.assign(this.temp, this.form);
      if (this.temp.id) {
        (0, _folder.update)(this.temp).then(success).catch(error);
      } else {
        (0, _folder.create)(this.temp).then(success).catch(error);
      }
      this.visible = false;
    }
  }
};
exports.default = _default;