"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  user: function user(state) {
    return state.user.info;
  },
  token: function token(state) {
    return state.user.token;
  },
  authorize: function authorize(state) {
    return state.user.authorize;
  },
  owner: function owner(state) {
    return state.user.owner;
  },
  pos_owner: function pos_owner(state) {
    return state.user.pos_owner;
  },
  config: function config(state) {
    return state.core.config;
  },
  msg: function msg(state) {
    return state.app.msg;
  },
  apps: function apps(state) {
    return state.app.apps;
  },
  appNavbar: function appNavbar(state) {
    return state.app.appNavbar;
  },
  appRoutes: function appRoutes(state) {
    return state.app.appRoutes;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  }
};
var _default = getters;
exports.default = _default;