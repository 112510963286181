"use strict";

var _interopRequireDefault = require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _MenuItem = _interopRequireDefault(require("./MenuItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  components: {
    Logo: _Logo.default,
    MenuItem: _MenuItem.default
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['appNavbar', 'sidebar', 'config'])), {}, {
    textColor: function textColor() {
      return this.config.text_color;
    },
    backgroundColor: function backgroundColor() {
      var theme = this.config.background_color;
      // 修改全局scss在styles/variables.scss中定义，hover和active样式定义在sidebar.scss中
      var subMenuHover = this.mixColor(theme, 0.2) || "";
      document.getElementsByTagName("body")[0].style.setProperty("--myStyle", subMenuHover);
      return theme;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {
    getColorChannels: function getColorChannels(color) {
      color = color.replace('#', '');
      if (/^[0-9a-fA-F]{3}$/.test(color)) {
        color = color.split('');
        for (var i = 2; i >= 0; i--) {
          color.splice(i, 0, color[i]);
        }
        color = color.join('');
      }
      if (/^[0-9a-fA-F]{6}$/.test(color)) {
        return {
          red: parseInt(color.slice(0, 2), 16),
          green: parseInt(color.slice(2, 4), 16),
          blue: parseInt(color.slice(4, 6), 16)
        };
      } else {
        return {
          red: 255,
          green: 255,
          blue: 255
        };
      }
    },
    mixColor: function mixColor(color, percent) {
      var _this$getColorChannel = this.getColorChannels(color),
        red = _this$getColorChannel.red,
        green = _this$getColorChannel.green,
        blue = _this$getColorChannel.blue;
      if (percent > 0) {
        // shade given color
        red *= 1 - percent;
        green *= 1 - percent;
        blue *= 1 - percent;
      } else {
        // tint given color
        red += (255 - red) * percent;
        green += (255 - green) * percent;
        blue += (255 - blue) * percent;
      }
      return "rgb(".concat(Math.round(red), ", ").concat(Math.round(green), ", ").concat(Math.round(blue), ")");
    }
  }
};
exports.default = _default;