"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  name: 'Item',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      collapse = _context$props.collapse;
    var vnodes = [];
    if (icon) {
      if (icon.indexOf('/') > -1) {
        vnodes.push(h("img", {
          "attrs": {
            "src": icon,
            "width": "18px",
            "height": "18px"
          },
          "class": "svg-icon"
        }));
      } else {
        vnodes.push(h("svg-icon", {
          "attrs": {
            "icon-class": icon
          }
        }));
      }
    }

    // if (title) {
    //   vnodes.push(<span slot='title'>{(title.substring(0,2))}</span>)
    // }

    if (title) {
      if (collapse) {
        vnodes.push(h("span", ["\xA0\xA0", title.substring(0, 2)]));
      } else {
        vnodes.push(h("span", {
          "slot": 'title'
        }, [title]));
      }
    }
    return vnodes;
  }
};
exports.default = _default;