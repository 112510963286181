"use strict";

var _interopRequireDefault = require("D:/www/taiqiu/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _utils = require("@/utils");
var _openWindow = _interopRequireDefault(require("@/utils/open-window"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'ImageUpload',
  props: {
    value: {
      type: [Array, String],
      default: function _default() {
        return [];
      }
    },
    config: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        fileName: 'file',
        limit: 5,
        multiple: false,
        accept: 'image/*',
        action: '',
        drag: false
      }
    },
    header: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        'x-access-appid': '',
        'x-access-token': ''
      }
    }
  },
  data: function data() {
    return {
      limit: this.config.limit,
      fileName: this.config.fileName,
      multiple: this.config.multiple,
      accept: this.config.accept,
      action: this.config.action,
      headers: this.header,
      drag: this.config.drag,
      dataobj: {
        filename: this.config.fileName
      }
    };
  },
  computed: {
    fileList: function fileList() {
      var imgarr = [];
      if (this.value === '') {
        return [];
      }
      if (typeof this.value === 'string') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = this.value.split(',');
      }
      for (var i = 0; i < this.value.length; i++) {
        imgarr.push({
          url: this.value[i]
        });
      }
      return imgarr;
    }
  },
  methods: {
    emitInput: function emitInput(val) {
      this.$emit('input', val);
    },
    handleRemove: function handleRemove(file, fileList) {
      if (fileList.length > 0) {
        this.emitInput((0, _utils.getArrByKey)(fileList, 'url'));
      } else {
        this.emitInput([]);
      }
    },
    handlePreview: function handlePreview(file) {
      (0, _openWindow.default)(file.url, '图片预览', '500', '400');
    },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.error('最多上传' + this.limit + '张图片');
    },
    beforeUpload: function beforeUpload(file) {
      var isIMG = file.type === 'image/jpeg' || file.type === 'image/png';
      var isLt5M = file.size / 1024 / 1024 < 5;
      if (!isIMG) {
        this.$message.error('上传图片只能是 JPG、PNG 格式!');
        return false;
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        return false;
      }
      return isIMG && isLt5M;
    },
    onProgress: function onProgress(event, file, fileList) {
      // console.log('========onProgress=========')
      // console.log(event, file, fileList)
      // console.log('========onProgress=========')
    },
    handleSuccess: function handleSuccess(res, file, fileList) {
      if (res.status === 1) {
        for (var i = 0; i < fileList.length; i++) {
          if (fileList[i]['uid'] === file['uid']) {
            fileList[i]['url'] = res.data.url;
            break;
          }
        }
        this.emitInput((0, _utils.getArrByKey)(fileList, 'url'));
      } else {
        for (var _i = 0; _i < fileList.length; _i++) {
          if (fileList[_i]['uid'] === file['uid']) {
            fileList.splice(_i, 1);
            break;
          }
        }
        this.$message.error(res.msg);
      }
    },
    handleError: function handleError(err, file, fileList) {
      this.$message.error(err);
    }
  }
};
exports.default = _default2;