"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
var state = {
  config: {
    logo: 'http://www.simplestart.cn/logo.png',
    text_color: '#fffff',
    background_color: '#06478c',
    title: '简艺科技',
    slogan: '为需求提供最简单有效的解决方案',
    domain: 'http://www.simplestart.cn',
    copyright: 'COPYRIGHT © 2017-2022 SIMPLESTART ALL RIGHTS RESERVED.',
    analysis: '',
    contact: '',
    phone: '',
    email: '',
    icp: '',
    cookie_expires: 1
  }
};
var mutations = {
  UPDATE_CONFIG: function UPDATE_CONFIG(state, data) {
    state.config = JSON.parse(JSON.stringify(data));
  },
  MERGE_CONFIG: function MERGE_CONFIG(state, data) {
    var deepMerge = function deepMerge(origin) {
      var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      for (var key in target) {
        if (Object.prototype.hasOwnProperty.call(target, key)) {
          var value = target[key];
          var isObj = Object.prototype.toString.call(value) === '[object Object]';
          if (origin.hasOwnProperty(key) && isObj) {
            deepMerge(origin[key], value);
          } else {
            origin[key] = value;
          }
        }
      }
    };
    deepMerge(state.config, data);
  }
};
var actions = {
  // 覆盖式更新
  updateConfig: function updateConfig(_ref, data) {
    var commit = _ref.commit;
    commit('UPDATE_CONFIG', data);
  },
  // 合并更新
  mergeConfig: function mergeConfig(_ref2, data) {
    var commit = _ref2.commit;
    commit('MERGE_CONFIG', data);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;